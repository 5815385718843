import axios from "axios";
import commonActions, { SetWorkItemDetailData, SetWorkItemListData, SetDTNListData, SetDTNDetailData } from "../../common.actions";
import getApiUrl from "../../helpers/api-urls";
import { getApiUniqueRequestId, getMessage } from "../../helpers/util-common";
import messages from '../../assets/i18n/en';
import { AuditLogTypeEnum, CollabApiFailureActionEnum } from "../../pages/WorkItemDetails/workItemDetail.model";
import * as _types from '../../action-type';
export var getWorkItemListAction = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getWorkItemList");
    axios
        .post(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.status) {
            callback(response.data.content);
            dispatch(SetWorkItemListData(response.data.content));
            dispatch(commonActions.setLoader(false));
        }
        else {
            // Handle error case
            dispatch(SetWorkItemListData({ hasError: true }));
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
export var getDTNListAction = function (dispatch, data, isDetails, callBack) {
    if (isDetails === void 0) { isDetails = false; }
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("workItems", "getDTNList");
    axios
        .post(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.status) {
            if (isDetails) {
                dispatch(SetDTNDetailData(response.data.content));
                callBack(response.data.content);
            }
            else
                dispatch(SetDTNListData(response.data.content));
            callBack(response.data.content);
            dispatch(commonActions.setLoader(false));
        }
        else {
            // Handle error case
            if (isDetails)
                dispatch(SetDTNDetailData({ hasError: true }));
            else
                dispatch(SetDTNListData({ hasError: true }));
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
export var getWorkItemDetailAction = function (dispatch, workItemNumber) {
    dispatch(commonActions.setLoader(true));
    var url = "".concat(getApiUrl("documents", "getWorkDetailList")).concat(workItemNumber);
    axios
        .get(url)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.content && response.data.content.WorkItemId) {
            dispatch(SetWorkItemDetailData(response.data.content));
            dispatch(commonActions.setLoader(false));
        }
        else {
            dispatch(SetWorkItemListData({ hasError: true }));
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
export var putCollaborationWorkItemAction = function (req, dispatch, callback) {
    var collabResponse = { status: false, apiTime: 0 };
    var startTime = performance.now();
    var endTime = performance.now();
    var timer = setTimeout(function () {
        endTime = performance.now();
        collabResponse.apiTime = endTime - startTime;
        callback(collabResponse);
    }, 10000);
    axios.defaults.withCredentials = true;
    var url = process.env.REACT_APP_SPECBUILDER_CONNECT_HOST;
    axios.post("".concat(url, "account/switch?accountId=").concat(process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID), '', req.OKTA_Token)
        .then(function (response) {
        clearTimeout(timer);
        axios.defaults.withCredentials = true;
        req.collabWorkItemInfo.inlineEditingForDraft = false;
        axios.put("".concat(url, "groups/work-item?accountID=").concat(process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID), req.collabWorkItemInfo, req.OKTA_Token)
            .then(function (specResponse) {
            clearTimeout(timer);
            dispatch(commonActions.setLoader(false));
            collabResponse.status = true;
            callback(collabResponse);
        })
            .catch(function (error) {
            clearTimeout(timer);
            callback(collabResponse);
            dispatch(commonActions.setLoader(false));
        });
    })
        .catch(function (error) {
        clearTimeout(timer);
        callback(collabResponse);
        dispatch(commonActions.setLoader(false));
    });
};
export var updateWorkItemDetailsAction = function (dispatch, data, callback) {
    var url = getApiUrl('workItemAdmin', 'updateWorkItemDetails');
    var wkNumber = data.WorkItemNumber.replace(/^WK0*(\d+)$/, 'WK$1');
    dispatch(commonActions.setLoader(true));
    axios
        .put(url, data)
        .then(function (response) {
        var _a = response.data, status = _a.status, content = _a.content, message = _a.message;
        if (status) {
            if (content &&
                content.collabWorkItemInfo &&
                content.OKTA_Token &&
                content.OKTA_Token.headers) {
                putCollaborationWorkItemAction(content, dispatch, function (collabRes) {
                    dispatch(commonActions.setLoader(false));
                    if (!collabRes.status) {
                        postCollabApiFailureEmail(content.collabWorkItemInfo.metaData.id, 1);
                        setCollabAPIFailedPopup(getMessage(messages, '9175.text'), dispatch);
                        postWorkItemAuditLog(content.collabWorkItemInfo.metaData.id, AuditLogTypeEnum.collabEditApiFailed);
                    }
                    else {
                        dispatch(commonActions.showSuccessToastMesage(true, "", "Work Item ".concat(wkNumber, " successfully updated!")));
                    }
                    callback();
                });
            }
            else {
                dispatch(commonActions.setLoader(false));
                callback();
                dispatch(commonActions.showSuccessToastMesage(true, "", "Work Item ".concat(wkNumber, " successfully updated!")));
            }
        }
        else {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, message || '')); // Default to empty string if message is undefined
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '9077'));
    });
};
export var getStandardTypeListAction = function (dispatch, callback) {
    var url = getApiUrl('documents', 'getStandardTypeList');
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        callback(response.data.content);
    })
        .catch(function (err) {
        dispatch(commonActions.setMessage(true, '9076'));
    });
};
export var deleteWorkItemAction = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("workItemAdmin", "deleteWorkItem");
    var wkNumber = data.WorkItemNumber.replace(/^WK0*(\d+)$/, 'WK$1');
    axios
        .post(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            // Audit log capture if colab API failed to restore
            if (Number(response.data.message) === 1018) {
                postCollabApiFailureEmail(data.WorkItemNumber.toLowerCase().replace("wk", ""), CollabApiFailureActionEnum.Delete);
                setCollabAPIFailedPopup(getMessage(messages, "1018.text"), dispatch);
                postWorkItemAuditLog(data.WorkItemNumber.toLowerCase().replace("wk", ""), AuditLogTypeEnum.collabDeleteApiFailed);
            }
            else {
                dispatch(commonActions.showSuccessToastMesage(true, "", "Work Item ".concat(wkNumber, " successfully deleted!")));
            }
            callback(response.data.status);
        }
        else {
            // Handle error case
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
            callback(false);
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
export var postWorkItemAuditLog = function (workitemNumber, logType) {
    var url = "".concat(getApiUrl('workItemAdmin', 'auditLog'));
    var data = {
        WorkItemNumber: workitemNumber,
        LogType: logType
    };
    axios.post(url, data)
        .then(function (response) {
        // Todo
    })
        .catch(function (err) {
        // Todo
    });
};
export var getDTNDetailAction = function (dispatch, data, isDetails) {
    if (isDetails === void 0) { isDetails = false; }
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("workItemAdmin", "getDTNDetailAction");
    axios
        .post(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.status) {
            if (isDetails)
                dispatch(SetDTNDetailData(response.data.content));
            else
                dispatch(SetDTNListData(response.data.content));
            dispatch(commonActions.setLoader(false));
        }
        else {
            // Handle error case
            if (isDetails)
                dispatch(SetDTNDetailData({ hasError: true }));
            else
                dispatch(SetDTNListData({ hasError: true }));
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
export var setCollabAPIFailedPopup = function (collabApiErrorMessage, dispatch) {
    dispatch({ type: _types.SET_COLLAB_API_FAILED, data: collabApiErrorMessage });
};
export var postCollabApiFailureEmail = function (workitemNumber, action) {
    var url = "".concat(getApiUrl('workItemAdmin', 'sendCollabApiFailureEmail'));
    var data = {
        WorkItemNumber: "WK".concat(workitemNumber),
        Action: action
    };
    axios.post(url, data)
        .then(function (response) {
        // Todo
    })
        .catch(function (err) {
        // Todo
    });
};
export var getWorkItemAuditLogsAction = function (data, dispatch, callback) {
    var url = getApiUrl('workItemAdmin', 'workItemAuditLogs');
    dispatch(commonActions.setLoader(true));
    axios.get(url, { params: { requestData: JSON.stringify(data) } })
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            callback(response.data.content);
        }
        else {
            // Handle error case
            dispatch(commonActions.setMessage(true, response.data.message));
            callback(false);
        }
    })
        .catch(function (err) {
        callback(false);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '3000'));
    });
};
export var getMemberListAction = function (mainCommitteeId, dispatch, callback) {
    var url = "".concat(getApiUrl('workItemAdmin', 'getMemberList')).concat(mainCommitteeId);
    dispatch(commonActions.setLoader(true));
    axios.get(url)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            callback(response.data);
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '9109'));
    });
};
export var getDraftBallotHistoryAction = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("workItems", "getDraftBallotHistory");
    axios
        .post(url, data)
        .then(function (response) {
        if (response && response.data.status) {
            callback(response.data.content);
            dispatch(commonActions.setLoader(false));
        }
        else {
            callback({});
            dispatch(commonActions.setLoader(false));
        }
    })
        .catch(function (err) {
        callback({});
        dispatch(commonActions.setLoader(false));
    });
};
var restoreCollaborationWorkItemAction = function (req, dispatch, callback) {
    var collabResponse = { status: false, apiTime: 0 };
    var startTime = performance.now();
    var endTime = performance.now();
    var timer = setTimeout(function () {
        endTime = performance.now();
        collabResponse.apiTime = endTime - startTime;
        callback(collabResponse);
    }, 10000);
    var url = process.env.REACT_APP_SPECBUILDER_CONNECT_HOST;
    axios.defaults.withCredentials = true;
    axios.post("".concat(url, "account/switch?accountId=").concat(process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID), '', req.OKTA_Token)
        .then(function (response) {
        clearTimeout(timer);
        axios.get(req.Collab_Url, req.OKTA_Token)
            .then(function (collabRes) {
            clearTimeout(timer);
            collabResponse.status = true;
            callback(collabResponse);
        })
            .catch(function (error) {
            clearTimeout(timer);
            callback(collabResponse);
            dispatch(commonActions.setLoader(false));
        });
    })
        .catch(function (error) {
        clearTimeout(timer);
        callback(collabResponse);
        dispatch(commonActions.setLoader(false));
    });
};
export var putRestoreWorkItemAction = function (dispatch, req, callback) {
    var url = "".concat(getApiUrl('workItemAdmin', 'restoreWorkItem')).concat(req.WorkItemId, "?WorkItemNumber=").concat(req.WorkItemNumber, "&memberId=").concat(req.CreatedBy, "&IsOnlineCollaboration=").concat(req.IsOnlineCollaboration, "&RestoreWithCollaboration=").concat(req.RestoreWithCollaboration, "&collabId=").concat(req.collabId);
    dispatch(commonActions.setLoader(true));
    var wkNumber = req.WorkItemNumber.replace(/^WK0*(\d+)$/, 'WK$1');
    axios.put(url, req)
        .then(function (response) {
        if (response.data.status) {
            if (response.data.content && response.data.content.OKTA_Token && response.data.content.Collab_Url) {
                restoreCollaborationWorkItemAction(response.data.content, dispatch, function (collabRes) {
                    if (response.data.content.collabWorkItemInfo && collabRes.status) {
                        putCollaborationWorkItemAction(response.data.content, dispatch, function (updateCollabRes) {
                            dispatch(commonActions.setLoader(false));
                            // Audit log capture if colab API failed to restore
                            if (!updateCollabRes.status) {
                                postCollabApiFailureEmail(req.WorkItemNumber.toLowerCase().replace('wk', ''), CollabApiFailureActionEnum.Restore);
                                setCollabAPIFailedPopup(getMessage(messages, '9174.text'), dispatch);
                                postWorkItemAuditLog(req.WorkItemNumber.toLowerCase().replace('wk', ''), AuditLogTypeEnum.collabRecoverApiFailed);
                            }
                            else {
                                dispatch(commonActions.showSuccessToastMesage(true, "", "Work Item ".concat(wkNumber, " successfully restored!")));
                            }
                            callback(response.data.status);
                        });
                    }
                    else {
                        dispatch(commonActions.setLoader(false));
                        // Audit log capture if colab API failed to restore
                        if (!collabRes.status) {
                            postCollabApiFailureEmail(req.WorkItemNumber.toLowerCase().replace('wk', ''), CollabApiFailureActionEnum.Restore);
                            setCollabAPIFailedPopup(getMessage(messages, '9174.text'), dispatch);
                            postWorkItemAuditLog(req.WorkItemNumber.toLowerCase().replace('wk', ''), AuditLogTypeEnum.collabRecoverApiFailed);
                        }
                        else {
                            dispatch(commonActions.showSuccessToastMesage(true, "", "Work Item ".concat(wkNumber, " successfully restored!")));
                        }
                        callback(response.data.status);
                    }
                });
            }
            else {
                dispatch(commonActions.setLoader(false));
                dispatch(commonActions.showSuccessToastMesage(true, "", "Work Item ".concat(wkNumber, " successfully restored!")));
                callback(response.data.status);
            }
        }
        else {
            dispatch(commonActions.setLoader(false));
            // Handle error case
            dispatch(commonActions.setMessage(true, response.data.message));
            callback(false);
        }
    });
};
