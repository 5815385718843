import axios from "axios";
import commonActions from "../../common.actions";
import { SECURITY_KEY } from "../../models/common.models";
import getApiUrl from "../../helpers/api-urls";
import { getApiUniqueRequestId } from "../../helpers/util-common";
export var getBallotDocumentsListAction = function (dispatch, data, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getBallotsList");
    axios
        .post(url, data)
        .then(function (response) {
        if (response && response.data.status) {
            callback(response.data.content);
            dispatch(commonActions.setLoader(false));
        }
        else {
            callback({});
            dispatch(commonActions.setLoader(false));
        }
    })
        .catch(function (err) {
        callback({});
        dispatch(commonActions.setLoader(false));
    });
};
export var getDownloadUrlAction = function (dispatch, url, callback) {
    dispatch(commonActions.setLoader(true));
    axios
        .get(url, {
        headers: SECURITY_KEY,
    })
        .then(function (response) {
        if (response && response.status) {
            callback(response.data.Result);
            dispatch(commonActions.setLoader(false));
        }
        else {
            callback({});
            dispatch(commonActions.setLoader(false));
        }
    })
        .catch(function (err) {
        callback({});
        dispatch(commonActions.setLoader(false));
    });
};
var allCommittees = function (committees) {
    var resultList = [];
    committees.forEach(function (committee) {
        resultList.push({ Designation: committee.Designation, Status: committee.Status });
        committee.SubCommitees.forEach(function (subCommittee) {
            resultList.push({ Designation: subCommittee.Designation, Status: subCommittee.Status });
        });
    });
    return resultList;
};
export var getActiveCommitteesAction = function (dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("openAPI", "getAllCommittees", true);
    url = url + "?fromDate=01-01-1900";
    axios
        .get(url, {
        headers: SECURITY_KEY,
    })
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.Status) {
            var committeesList = allCommittees(response.data.Result);
            callback(committeesList);
        }
        else {
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
    });
};
export var getUserPreferencesAction = function (pageId, userEmail, dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getUserPreferences");
    axios
        .get(url, {
        params: {
            pageId: pageId,
            userEmail: userEmail,
        },
    })
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            callback(response.data.content);
        }
        else {
            callback({});
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
    });
};
export var saveUserPreferencesAction = function (data, dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "saveUserPreferences");
    axios
        .post(url, data)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            dispatch(commonActions.setMessage(true, "1015", uniqueRequestId));
            callback(response.data.content);
        }
        else {
            callback({});
            dispatch(commonActions.setMessage(true, "1016", uniqueRequestId));
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "1016", uniqueRequestId));
    });
};
export var getBallotTypesAction = function (dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getBallotTypes");
    axios
        .get(url)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
        }
        else {
            callback({});
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
        callback({});
    });
};
export var getActiveCommitteesFromIAAction = function (dispatch, callback) {
    var url = getApiUrl("documents", "getActiveCommitteesFromIA");
    axios
        .get(url)
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.status) {
            callback(response.data.content);
        }
        else {
            // Handle error case
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "7025", uniqueRequestId));
    });
};
export var saveBallotHeaderAction = function (data, dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "saveBallotHeader");
    axios
        .post(url, data)
        .then(function (response) {
        var _a, _b;
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response.data.status) {
            var folderReq = {
                Committee: data.CommitteeDesignation,
                DocumentYear: data.BallotYear,
                SequenceNumber: (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.SequenceNumber,
                CreatedBy: data.CreatedBy
            };
            postCreateNewFolderAction(folderReq, dispatch, function (folderResponse) {
                if (folderResponse === null || folderResponse === void 0 ? void 0 : folderResponse.Status) {
                    dispatch(commonActions.setMessage(true, "", uniqueRequestId, response.data.content.StatusMessage));
                    callback(response.data.content);
                }
                else {
                    dispatch(commonActions.setMessage(true, "1017", uniqueRequestId));
                    callback({});
                }
            });
        }
        else {
            dispatch(commonActions.setMessage(true, "1017", uniqueRequestId));
            callback({});
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "1017", uniqueRequestId));
    });
};
export var getAllBallotStatusAction = function (dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getAllBallotStatus");
    axios
        .get(url)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content);
        }
        else {
            callback({});
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
        callback({});
    });
};
export var getUserListAction = function (username, dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("documents", "getAppUserList") + "?username=".concat(username);
    axios
        .get(url)
        .then(function (response) {
        dispatch(commonActions.setLoader(false));
        if (response && response.data.status) {
            callback(response.data.content, response.data.isUserOfficer);
        }
        else {
            callback({});
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
        callback({});
    });
};
export var getBallotItemsDownloadUrlAction = function (data, dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("openAPI", "getBallotItemsDownloadUrl", true);
    var combinedFileName = encodeURIComponent("".concat(data.fileName, ".").concat(data.fileType));
    url += "?filename=".concat(data.fileName, "&itemnumber=").concat(data.itemNumber, "&committee=").concat(data.committee);
    axios
        .get(url, {
        headers: SECURITY_KEY,
    })
        .then(function (response) {
        var _a;
        dispatch(commonActions.setLoader(false));
        var uniqueRequestId = getApiUniqueRequestId(url);
        if (response.data.Status) {
            callback((_a = response.data) === null || _a === void 0 ? void 0 : _a.Result);
        }
        else {
            dispatch(commonActions.setMessage(true, response.data.message, uniqueRequestId));
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
    });
};
export var postCreateNewFolderAction = function (data, dispatch, callback) {
    dispatch(commonActions.setLoader(true));
    var url = getApiUrl("openAPI", "createNewFolder", true);
    axios
        .post(url, data, {
        headers: SECURITY_KEY,
    })
        .then(function (response) {
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setLoader(false));
        if (response.data.Status) {
            callback(response.data);
        }
        else {
            callback({});
        }
    })
        .catch(function (err) {
        dispatch(commonActions.setLoader(false));
        var uniqueRequestId = getApiUniqueRequestId(url);
        dispatch(commonActions.setMessage(true, "1017", uniqueRequestId));
    });
};
